import React, {useState, useContext} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TempLeftJoinedInput from './TempLeftJoinedInput.js';
import {FormContext} from './Contexts.js';

function makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 10) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default function TempLeftJoinedInputGroup({onChange, stateLeftInit, stateJoinedInit}) {

    const formContext = useContext(FormContext);
    const tempJoinedUnits = formContext.formMetadata.tempJoinedUnits;
    const tempLeftUnits = formContext.formMetadata.tempLeftUnits;

    const [stateLeft, setStateLeft] = useState(stateLeftInit ? stateLeftInit: {});
    const [stateJoined, setStateJoined] = useState(stateJoinedInit ? stateJoinedInit : {});

    const [allowedLeft, setAllowedLeft] = useState(tempJoinedUnits);
    const [allowedJoined, setAllowedJoined] = useState(tempLeftUnits);

    const [readyLeft, setReadyLeft] = useState(true);
    const [readyJoined, setReadyJoined] = useState(true);

    const updateTopState = (stateLeft, stateJoined) => {  
        const left = Object.entries(stateLeft)
            .filter(([_, unitState]) => unitState?.data !== undefined)
            .filter(([_, unitState]) => unitState.data !== 0)
            .map(([_, unitState]) => {return {targetUnitId: unitState.id, left: unitState.data}})
        
            const joined = Object.entries(stateJoined)
            .filter(([_, unitState]) => unitState?.data !== undefined)
            .filter(([_, unitState]) => unitState.data !== 0)
            .map(([_, unitState]) => {return {targetUnitId: unitState.id, joined: unitState.data}})
        console.log(left.concat(joined))
        onChange({"tempLeftList": left.concat(joined), "stateLeft": stateLeft, "stateJoined": stateJoined});
    };

    const recomputeAllowedJoined = (stateJoined) => {
        const usedUnits = Object.entries(stateJoined)
        .filter(([_, unitState]) => unitState?.id !== undefined && unitState?.id !== null)
        .map(([_, unitState]) => String(unitState.id))

        var recomputed = tempJoinedUnits.filter(unit => !usedUnits.includes(String(unit.id)))
        setAllowedJoined(recomputed)
        return recomputed;
    }

    const recomputeAllowedLeft = (stateLeft) => {
        const usedUnits = Object.entries(stateLeft)
        .filter(([_, unitState]) => unitState?.id !== undefined && unitState?.id !== null)
        .map(([_, unitState]) => String(unitState.id))

        var recomputed = tempLeftUnits.filter(unit => !usedUnits.includes(String(unit.id)))
        setAllowedLeft(recomputed)
        return recomputed;
    }

    const onChangeLeft = (id, newEntity) => {
        var newState = null
        const justRemoved = newEntity == null
        if (!justRemoved) {
            newState = {
                ...stateLeft,
                [id]: newEntity
            }
        } else {
            newState = stateLeft
            delete newState[id]
            if (emptyOrAllDisabled(newState, allowedLeft)) {
                recomputeAllowedLeft(newState)
            }
            // recomputeAllowedLeft(newState)
        }
        setStateLeft(newState)
        updateTopState(newState, stateJoined)
    }

    const onChangeJoined = (id, newEntity) => {
        var newState = null
        const justRemoved = newEntity == null
        if (!justRemoved) {
            newState = {
                ...stateJoined,
                [id]: newEntity
            }
        } else {
            newState = stateJoined
            delete newState[id]
            if (emptyOrAllDisabled(newState, allowedJoined)) {
                recomputeAllowedJoined(newState)
            }
            // recomputeAllowedJoined(newState)
        }

        setStateJoined(newState)
        updateTopState(stateLeft, newState)
    }

    const addJoined = () => {
        var allowed = recomputeAllowedJoined(stateJoined)
        setReadyJoined(false)
        onChangeJoined(makeid(), allowed[0], false)
    }

    const addLeft = () => {
        var allowed = recomputeAllowedLeft(stateLeft)
        setReadyLeft(false)
        onChangeLeft(makeid(), allowed[0], false)
    }

    const emptyOrAllDisabled = (state, allowed) => {
        var sizeAll = Object.entries(state).length
        var sizeDisabled = Object.entries(state).filter(([id, unit]) => unit?.disabled != null && unit?.disabled).length
        return sizeAll === 0 || (sizeAll === sizeDisabled && allowed.length > 0)
    }

    return (
        <>
            <Form.Group >
                <Form.Label>Прикомандировані</Form.Label> 
            </Form.Group>
            
            {Object.entries(stateJoined)
            .map(([id, unitState]) => 
            <TempLeftJoinedInput
                name={"inputTempJoined"}
                allowedUnits={allowedJoined}
                unitState={unitState} key={id}
                onChange={(u) => onChangeJoined(id, u)}
                notifyReady={() => {
                    recomputeAllowedJoined(stateJoined)
                    setReadyJoined(true)}}/>)}
            {(allowedJoined.length > 0 && readyJoined) || emptyOrAllDisabled(stateJoined, allowedJoined)
            ? <Button id="addTempJoinedBtn" size="sm" variant="primary" className="small-button" onClick={addJoined}>Додати</Button>
            : <></>}

            <Form.Group >
                <Form.Label>Відкомандировані</Form.Label>
            </Form.Group>
            
            {Object.entries(stateLeft)
            .map(([id, unitState]) => 
            <TempLeftJoinedInput
                name={"inputTempLeft"}
                allowedUnits={allowedLeft} unitState={unitState} key={id}
                onChange={(u) => onChangeLeft(id, u)}
                notifyReady={() => {
                    recomputeAllowedLeft(stateLeft)
                    setReadyLeft(true)
                }}/>)}
            {(allowedLeft.length > 0 && readyLeft) || emptyOrAllDisabled(stateLeft, allowedLeft)
            ? <Button id="addTempLeftBtn" size="sm" variant="primary" className="small-button" onClick={addLeft}>Додати</Button>
            : <></>}
        </>
    );
}