import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import React, { useState, useRef, useEffect } from 'react';

export default function Tooltip({ text }) {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const popoverBottom = (
        <Popover id="popover-positioned-top"
        className='p-2'
        style={{"color": "black", "backgroundColor": "light-gray"}}>
            {text}
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={popoverBottom}>
            <Button variant="transparent">
                <i class="fa fa-info-circle" style={{ color: 'gray' }} />
            </Button>
        </OverlayTrigger>
    );
}