import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function ObzInput({name, allowedObz, onChange, obzState, notifyReady}) {
    const [stateDisabledSelect,
        setStateDisabledSelect] = useState(false);

    const [stateName, setName] = useState(obzState?.name);  
    
    const chngFn = (event) => {
        const {name, value} = event.target;

        onChange({
            ...obzState,
            ["data"]: value,
            ["disabled"]: true
        });
        setStateDisabledSelect(true)
        notifyReady()
    };
    const chngJoinedFn = (event) => {
        const {name, value} = event.target;

        onChange({
            ...obzState,
            ["dataJoined"]: value,
            ["disabled"]: true
        });
        setStateDisabledSelect(true)
        notifyReady()
    };
    const chngTeamLeadFn = (event) => {
        const {name, value} = event.target;

        onChange({
            ...obzState,
            ["teamLead"]: value,
            ["disabled"]: true
        });
        setStateDisabledSelect(true)
        notifyReady()
    };
    
    return (
    <Container
        className="p-2 mb-4 bg-secondary-subtle rounded-3">


            <InputGroup>

                    {(obzState?.disabled != null && obzState?.disabled)
                        ? <Form.Label>{stateName}</Form.Label>
                        : <Form.Select
                            name={name + "Select"}
                            disabled={stateDisabledSelect}
                            variant="outline-secondary"
                            value={obzState["id"]}
                            onChange={(e) => {
                                const {value} = e.target;

                                const name = allowedObz.filter(obz => String(obz.id) === String(value))[0].name
                                setName(name)
                                onChange({
                                    ...obzState,
                                    ["id"]: value,
                                    ["name"]: name
                                })
                        }}>
                            {allowedObz.map(obz => <option key={obz.id} value={obz.id} name={obz.name}>{obz.name}</option>)}
                        </Form.Select>}
            </InputGroup>
            <InputGroup>
                    <Form.Control

                        name={name + "Input"}
                        type="number" min="0"
                        placeholder="Штатні"
                        onChange={(e) => {
                            setName(obzState.name)
                            chngFn(e)
                        }}
                        value={obzState.data || ''}/>
                    <Form.Control.Feedback type="invalid">
                        Лише цілі числа
                    </Form.Control.Feedback>
            </InputGroup>
            <InputGroup>
                    <Form.Control
                        name={name + "InputJoined"}
                        type="number" min="0"
                        placeholder="Прикомандировані"
                        onChange={(e) => {
                            setName(obzState.name)
                            chngJoinedFn(e)
                        }}
                        value={obzState.dataJoined || ''}/>
                    <Form.Control.Feedback type="invalid">
                        Лише цілі числа
                    </Form.Control.Feedback>
            </InputGroup>
            <InputGroup>
                    <Form.Control
                        name={name + "InputTeamLead"}
                        type="text"
                        placeholder="Старший від обз"
                        onChange={(e) => {
                            setName(obzState.name)
                            chngTeamLeadFn(e)
                        }}
                        value={obzState.teamLead || ''}/>
            </InputGroup>
                    <Button name={name + "DeleteButton"} variant="danger" onClick={() => onChange(null)}>Видалити</Button>
        </Container>
    );
}