import Container from 'react-bootstrap/Container';

export function ErrorPage() {
    return (
        <Container className="p-3">
            <Container
                className="p-5 mb-4 rounded-3 bg-danger p-2"
                style={{
                "--mdb-bg-opacity": "0.5"
            }}>
                <h1
                    className="header"
                    style={{
                    color: 'white'
                }}>Сталась помилка, будь ласка, розкажіть адмінам</h1>
            </Container>
        </Container>
    );
}