import Container from 'react-bootstrap/Container';

export function NoPage() {
    return (
        <Container className="p-3">
            <Container
                className="p-5 mb-4 rounded-3 bg-warning p-2"
                style={{
                "--mdb-bg-opacity": "0.5"
            }}>
                <h1
                    className="header"
                    style={{
                    color: 'black'
                }}>Сторінку не знайдено</h1>
            </Container>
        </Container>
    );
}