import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const apiUrl = `${baseURL}/v1/stats`;

export const submitForm = async (link, formData) => {
  try {
    const response = await axios.post(`${apiUrl}/${link}`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
}



export const getMetadata = async (link) => {
  try {
    const response = await axios.get(`${apiUrl}/${link}/metadata`);
    
    return response.data;
  } catch (error) {
    throw error;
  }
}