import CryptoJS from "crypto-js";

const clearState = async() => {
    localStorage.clear("crypto.state");
}

export const saveStateEncrypted = async(key, data) => {
    if (!key) {
        return;
    }
    const plaintextJSON = JSON.stringify(data);
    var ciphertext = CryptoJS.AES.encrypt(plaintextJSON, key).toString();
    localStorage.setItem("crypto.state", ciphertext)
};

export const getStateEncrypted = (key) => {

    try {
        const encryptedText = localStorage.getItem("crypto.state");
        if (!encryptedText) {
            return {};
        }

        var bytes  = CryptoJS.AES.decrypt(encryptedText, key);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(originalText)
    } catch (error) {
        clearState()
        return {};
    }

};