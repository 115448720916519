import React, {useState, useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import { ThemeContext, UserLinkContext, FormContext } from './Contexts.js';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { submitForm } from './services/ApiService.js';
import TempLeftJoinedInputGroup from './TempLeftJoinedInputGroup.js';
import ObzInputGroup from './ObzInputGroup.js';
import FormAlert from './FormAlert.js';
import Tooltip from './Tooltip.js';


export function GroupStatsForm() {
    const theme = useContext(ThemeContext);
    const userLink = useContext(UserLinkContext);
    const formContext = useContext(FormContext);
    
    const navigate = useNavigate();
    const navigateToSuccessPage = () => navigate('/forms/success/' + userLink);
    const navigateToErrorPage = () => navigate('/forms/error/' + userLink);
    const navigateToForbiddenPage = () => navigate('/forms/forbidden/' + userLink);

    const [validated,
        setValidated] = useState(false);

    const [showAlert,
        setShowAlert] = useState(false);

    const [alertText,
        setAlertText] = useState(null);

    const formData = formContext.formData
    const setFormData = formContext?.updateFormData;

    const chngFn = (event) => {
        const {name, value} = event.target;
        let preprocessedValue = value

        if (name === "unitLead" && value.trim() === "") {
            preprocessedValue = ""
        }

        setFormData({
                    ...formData,
                    [name]: preprocessedValue
                });
    };

    const zeroIfNull = (num) => (num == null || num === "" ? 0 : parseInt(num)) 

    const fieldsAddedCorrectly = (formData) => {
            let byListAllActualCalculated =
                zeroIfNull(formData.specialEvent) +
                zeroIfNull(formData.securityMeasureEvent) +
                zeroIfNull(formData.combatDuty) +
                zeroIfNull(formData.vacation) +
                zeroIfNull(formData.sickLeave) +
                zeroIfNull(formData.workingDay9h) +
                zeroIfNull(formData.workingAssignment) +
                zeroIfNull(formData.workingAssignmentOther) +
                zeroIfNull(formData.weekendWithGatherTime);

                let byListAllActualJoinedCalculated =
                zeroIfNull(formData.specialEventJoined) +
                zeroIfNull(formData.securityMeasureEventJoined) +
                zeroIfNull(formData.combatDutyJoined) +
                zeroIfNull(formData.vacationJoined) +
                zeroIfNull(formData.sickLeaveJoined) +
                zeroIfNull(formData.workingDay9hJoined) +
                zeroIfNull(formData.workingAssignmentJoined) +
                zeroIfNull(formData.workingAssignmentOtherJoined) +
                zeroIfNull(formData.weekendWithGatherTimeJoined);

                let byListAllActualJoinedCalculated1 = 0;

            if ("obzList" in formData) {
                    byListAllActualCalculated = byListAllActualCalculated + formData.obzList
                        .filter(obz => "value" in obz)
                        .map(obz => zeroIfNull(obz.value))
                        .reduce((a, b) => a + b, 0);
                    byListAllActualJoinedCalculated = byListAllActualJoinedCalculated + formData.obzList
                        .filter(obz => "valueJoined" in obz)
                        .map(obz => zeroIfNull(obz.valueJoined))
                        .reduce((a, b) => a + b, 0);
            }
            if ("tempLeftList" in formData) {
                byListAllActualJoinedCalculated1 = formData.tempLeftList
                        .filter(tempLeftJoined => "joined" in tempLeftJoined)
                        .map(tempLeftJoined => zeroIfNull(tempLeftJoined.joined))
                        .reduce((a, b) => a + b, 0);
            }

            const actual = zeroIfNull(formData.byListAllActual);

            if (byListAllActualCalculated !== actual) {
             setAlertText("Не спідпадає кількість 'За списком фактично: за штатом'" + 
                " (вказано: " + actual + ", насправді: " + byListAllActualCalculated + ")")
             setShowAlert(true)
             return false;
            }

            const actualJoined = zeroIfNull(formData.byListAllActualJoined);

            if (byListAllActualJoinedCalculated !== actualJoined) {
             setAlertText("Не спідпадає кількість 'За списком фактично: прикомандировані' з сумою інших полів" +
                " (вказано: " + actualJoined + ", насправді: " + byListAllActualJoinedCalculated + ")")
             setShowAlert(true)
             return false;
            }
            if (byListAllActualJoinedCalculated1 !== actualJoined) {
             setAlertText("Не спідпадає кількість 'За списком фактично: прикомандировані' та в блоці 'Прикомандировані'" +
                " (вказано: " + actualJoined + ", насправді: " + byListAllActualJoinedCalculated1 + ")")
             setShowAlert(true)
             return false;
            }

            setShowAlert(false)
            return true;
        };

    async function submit(target) {
        target.preventDefault();
        
        if (target.currentTarget.checkValidity() === false) {
            target.stopPropagation();
            setValidated(true);
            return;
        }
         setValidated(true);
         if (!fieldsAddedCorrectly(formData)) {
            target.stopPropagation();
            return;
        }
        console.log("general")

        const data = formData
        delete data["stateLeft"]
        delete data["stateJoined"]
        delete data["obzState"]

        await submitForm(userLink, data)
            .then(res => navigateToSuccessPage())
            .catch(e => {
                if (e
                    ?.response
                        ?.status === 403) {
                    navigateToForbiddenPage();
                } else {
                    navigateToErrorPage();
                }
            });
    }
    return (
        <Container className="p-3" style={theme}>

            <Container
                className="p-5 mb-4 bg-light rounded-3"
                style={{
                "opacity": "0.96"
            }}>
                <h1 className="header">Форма для заповнення</h1>
                <Form
                    style={{
                    "--mdb-bg-opacity": "0.5"
                }}
                    noValidate
                    validated={validated}
                    onSubmit={(e) => submit(e)}>                        
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>За штатом</Form.Label><Tooltip text="кількість штатних посад в підрозділі"/>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="byState" value={formData.byState}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>За списком юридично</Form.Label><Tooltip text="кількість заповнених посад в штаті підрозділу"/>
                        <Form.Control type="number" min="0"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="byListOnPaper" value={formData.byListOnPaper}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>За списком фактично</Form.Label>
                        <Form.Control type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="byListAllActual" value={formData.byListAllActual}/>
                        <Form.Control type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="byListAllActualJoined" value={formData.byListAllActualJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>СЗ</Form.Label><Tooltip text="задіяні в спеціальних заходах"/>
                        <Form.Control type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="specialEvent" value={formData.specialEvent}/>
                        <Form.Control type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="specialEventJoined" value={formData.specialEventJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>ЗЗБ</Form.Label><Tooltip text="задіяні в засобах забезпечення безпеки"/>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="securityMeasureEvent" value={formData.securityMeasureEvent}/>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="securityMeasureEventJoined" value={formData.securityMeasureEventJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>БЧ</Form.Label><Tooltip text="добове бойове чергування"/>
                        <Form.Control type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="combatDuty" value={formData.combatDuty}/>
                        <Form.Control type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="combatDutyJoined" value={formData.combatDutyJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Відпустка (короткотривала)</Form.Label>
                        <Form.Control type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="vacation" value={formData.vacation}/>
                        <Form.Control type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="vacationJoined" value={formData.vacationJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Лікарняний</Form.Label>
                        <Form.Control type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="sickLeave" value={formData.sickLeave}/>
                        <Form.Control type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="sickLeaveJoined" value={formData.sickLeaveJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>9 - робочий день</Form.Label>
                        <Form.Control type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="workingDay9h" value={formData.workingDay9h}/>
                        <Form.Control type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="workingDay9hJoined" value={formData.workingDay9hJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Відрядження</Form.Label>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="workingAssignment" value={formData.workingAssignment}/>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="workingAssignmentJoined" value={formData.workingAssignmentJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Збори</Form.Label>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="workingAssignmentOther" value={formData.workingAssignmentOther}/>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="workingAssignmentOtherJoined" value={formData.workingAssignmentOtherJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Вихідний (1.5 год на збір)</Form.Label>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Штатні співробітники"
                            onChange={chngFn}
                            name="weekendWithGatherTime" value={formData.weekendWithGatherTime}/>
                        <Form.Control
                            type="number" min="0"
                            placeholder="Прикомандировані"
                            onChange={chngFn}
                            name="weekendWithGatherTimeJoined" value={formData.weekendWithGatherTimeJoined}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <ObzInputGroup
                    obzStateInit={formData.obzState}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            ["obzList"]: e.obzList,
                            ["obzState"]: e.obzState
                        });
                    }}/>

                    <TempLeftJoinedInputGroup
                    stateLeftInit={formData.stateLeft} 
                    stateJoinedInit={formData.stateJoined}
                    onChange={(e) => {

                        setFormData({
                            ...formData,
                            ["tempLeftList"]: e.tempLeftList,
                            ["stateLeft"]: e.stateLeft,
                            ["stateJoined"]: e.stateJoined
                        });
                    }}/>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Старший від підрозділу</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Вкажіть текст"
                            onChange={chngFn}
                            name="unitLead" value={formData.unitLead}/>
                    </Form.Group>

                    <FormAlert show={showAlert} onClose={() => setShowAlert(false)} text={alertText} />

                    <div className="d-grid gap-2 main-button">
                        <Button variant="success" size="lg" type="submit">
                            Завершити звіт
                        </Button>
                    </div>
                </Form>
            </Container>
        </Container>
    );
}