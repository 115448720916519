import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function TempLeftJoinedInput({name, allowedUnits, onChange, unitState, notifyReady}) {
    const [stateDisabledSelect,
        setStateDisabledSelect] = useState(false);

    const [stateName, setName] = useState(unitState?.name);  
    
    const chngFn = (event) => {
        const {name, value} = event.target;
        onChange({
            ...unitState,
            ["data"]: value,
            ["disabled"]: true
        });
        setStateDisabledSelect(true)
        notifyReady()
    };
    
    return (
        <Row className="g-2">
            <InputGroup className="mb-3">
                <Col lg="2">
                    {(unitState?.disabled != null && unitState?.disabled)
                        ? <InputGroup.Text>{stateName}</InputGroup.Text>
                        : <Form.Select
                            name={name + "Select"}
                            disabled={stateDisabledSelect}
                            variant="outline-secondary"
                            value={unitState["id"]}
                            onChange={(e) => {
                            const {value} = e.target;
                            
                            const name = allowedUnits.filter(unit => String(unit.id) === String(value))[0].name
                            setName(name)
                            onChange({
                                ...unitState,
                                ["id"]: value,
                                ["name"]: name
                            })
                        }}>
                            {allowedUnits.map(unit => <option key={unit.id} value={unit.id} name={unit.name}>{unit.name}</option>)}
                        </Form.Select>}
                </Col>
                <Col>
                    <Form.Control
                        name={name + "Input"}
                        type="number" min="0"
                        placeholder="Вкажіть число"
                        onChange={(e) => {
                            setName(unitState.name)
                            chngFn(e)
                        }}
                        value={unitState.data || ''}/>
                    <Form.Control.Feedback type="invalid">
                        Лише цілі числа
                    </Form.Control.Feedback>
                </Col>
                <Col lg="2">
                    <Button name={name + "DeleteButton"} variant="danger" onClick={() => onChange(null)}><i class="fa fa-trash"/></Button>
                </Col>
            </InputGroup>
        </Row>
    );
}