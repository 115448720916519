import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

export default function FormAlert({show, text, onClose}) {

    return (

        <Alert name="formAlert" variant="danger" show={show} onClose={onClose} dismissible>
                <Alert.Heading>Помилка введення</Alert.Heading>
                <p>
                  {text}
                </p>
              </Alert>
);
}